<div class="empty-table-container">
  <wj-app-header></wj-app-header>
  <div class="table-message-container">
    <p class="table-title">{{'service-unavailable'|translate}}</p>
    <div class="table-message">
      {{'scan-qr'| translate}} <span class="loc-name">Aspire Lounge</span> {{'services' | translate}}.
    </div>
  </div>
  <img class="table-image" [src]="service_unavailable" />
</div>
