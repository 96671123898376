import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import {
  NotificationBarBaseComponent,
  MenuItemCreation,
  CartService,
  CartState,
  Cart,
  CartItems,
  LocationState,
  LocationStatus,
  DeliveryState,
  Delivery,
} from '@moduurnv2/libs-orderingapp';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'wj-notification-bar',
  templateUrl: 'wj-notification-bar.component.html',
  styleUrls: ['wj-notification-bar.component.scss'],
})
export class WJNotificationBarComponent extends NotificationBarBaseComponent
  implements OnInit {
  @Input('menuitem') menuItem: any[];
  @Output() viewReview = new EventEmitter();

  selectedMenuItem: MenuItemCreation[];
  @Select(CartState.getCart) cart$: Observable<Cart>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  @Select(LocationState.getLocationStatus) locationStatus$: Observable<
    LocationStatus
  >;
  @Select(DeliveryState.getSelectedDelivery) delivery$: Observable<Delivery>;
  value: number;
  isEmpty: boolean;
  // virtual: boolean;
  locCloseMsg: string;
  myCart: any;
  // locationStatus: LocationStatus;
  delivery: Delivery;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
  };
  constructor(public cartstate: CartState, store: Store) {
    super(store);
    this.isEmpty = true;
    this.value = 0;
    this.cart$.subscribe((response) => {
      this.myCart = response;
    });
    this.delivery$.pipe(takeUntil(this.destroy$)).subscribe((delivery) => {
      if (delivery) {
        this.delivery = delivery;
      }
    });
    
  }

  ngOnInit() {
    this.getLocationUpdate();
  }

  getLocationUpdate() {
    // this.virtual = this.delivery.textCode == 'DELIVERYTYPE04' ? true : false;
    // if (this.delivery.textCode == 'DELIVERYTYPE05') {
    //   this.locCloseMsg = 'You can place the order once the event starts';
    // } else {
    //   if (this.delivery.textCode == 'DELIVERYTYPE02') {
    //     this.locCloseMsg = 'Ordering is closed';
    //   } else {
        this.locCloseMsg ='location-closed';
    //   }
    // }
  }

  emitReviewEvent() {
    const el: HTMLElement | null = document.getElementById('viewOrderBtn');
    if (el) this.viewReview.emit();
  }
  itemText(count) {
    return count > 1 ? 'items' : 'item';
  }
}
