import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
@Component({
  selector: 'wj-special-instructions',
  templateUrl: './wj-special-instructions.component.html',
  styleUrls: ['./wj-special-instructions.component.scss'],
})
export class WJSpecialInstructionsComponent implements OnInit {
  @Input() modalStatus: boolean = false;
  @Input() heading;
  @Input() requireOverlay: boolean = true;
  @Input() topMargin: string = "'0'";
  @Output() closePopupEvent = new EventEmitter();
  constructor() {}
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
  };
  themeClose: ThemeOptions = {
  };
  ngOnInit(): void {}
}
