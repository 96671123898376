import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  MenuItemState,
  FetchSelectedMenuItem,
  SubmitModifierItem,
  UpdateItemCount,
  environment,
  LocationDefaultState,
  SweetAlertService,
} from '@moduurnv2/libs-orderingapp/src';
import { LanguageLocalize } from '@moduurnv2/features/ui/pipes/language-localize.pipe';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import { AlertPopupComponent } from '@moduurnv2/web/features/ui/components/alert-popup/alert-popup.component';

@Component({
  selector: 'wj-modifier',
  templateUrl: 'wj-modifier.component.html',
  styleUrls: ['wj-modifier.component.scss'],
})
export class WJModifierComponent implements OnInit {
  @Select(MenuItemState.getSelectedMenuItem) selectedMenuItem$: Observable<any>;
  @Select(MenuItemState.getMenuItemPrice) price$: Observable<any>;
  @Select(MenuItemState.getGeneratedCount) count$: Observable<any>;
  @Select(MenuItemState.getItemDetails) details$: Observable<any>;

  @Input() itemDetail: string;
  @Input() isEdit: boolean;
  @Output() closeModifier = new EventEmitter();
  _data: any = null;
  imageBase = environment.imagebaseurl;
  close = environment.image_basepath + 'WJ_Close_Icon.svg';
  checkIfEdit: boolean = false;
  submitClick: boolean = false;
  allowNoPaymentOrdering: boolean = false;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
  };
  dataPassedToModal: any;
  value = 1;
  _modalClose: any = null;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private modal: ModalService,
    private translate: TranslateService,
    private sweetAlert:SweetAlertService,
    private languageLocalize:LanguageLocalize
  ) {}

  ngOnInit() {
    if(this._data) this.populateData(this._data);
  }

  populateData(data){
    this.itemDetail = data.menuItemId;
    this.isEdit = data.isEdit;
    if (this.itemDetail) this.loadInitialData(this.itemDetail);
  }

  loadInitialData(menuItemId) {
    this.store.dispatch(new FetchSelectedMenuItem(menuItemId));
    this.checkIfEdit = this.store.selectSnapshot(MenuItemState.getCartDetails);
    this.checkNoPaymentOrdering();
  }

  choiceText(modifierItem) {
    const { isForced, maxLimit, minLimit } = modifierItem;
    let choiceOption = this.translate.instant('please-select');
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');

    if (maxLimit === 1) return `${choiceOption} 1 `+this.translate.instant('option')+ ` (${endText})` ;
    else if (maxLimit > 0)
      if (!minLimit || minLimit === 0) return `${choiceOption} ${this.translate.instant('upto')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else if (minLimit === maxLimit)
        return `${choiceOption} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else return `${choiceOption} ${this.translate.instant('between')} ${minLimit} ${this.translate.instant('and')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
  }

  headerErrors(modifierValid, id = null) {
    const submitted = this.store.selectSnapshot(MenuItemState.getSubmitted);
    if (this.submitClick && submitted && modifierValid === false) {
      var element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        this.submitClick = false;
      }
    }

    return submitted ? modifierValid === false : false;
  }

  submitMenuItem() {
    this.submitClick = true;
    const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );
     const currentCount = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
      if(this.validateMaxItemsPerUser(selectedMenuItem._id,currentCount)){
        this.store.dispatch(new SubmitModifierItem());
        if (selectedMenuItem.valid)
        // this.closeModifier.emit();
        this.closePopup();
      }
  }

  increaseCounter() {
    this.changeCounter(1);
  }

  decreaseCounter() {
    this.changeCounter(-1);
  }

  changeCounter(count) {
   const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );
    const currentCount = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
    console.log('currentCount->',currentCount, ' count ->', count)
    if(this.validateMaxItemsPerUser(selectedMenuItem._id,currentCount+count)){
    this.store.dispatch(new UpdateItemCount(count));
    }
  }

  closePopup() {
    this.modal.closeAllModals();
  }

  set data(data: any) {
    this._data = data;
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }

  // getActionCaption({ actionCaption,translatedactionCaption, name,translation }) {
  //   if (actionCaption) return `${actionCaption} ${name}`;
  //   else return this.translate.instant('choose-your')+` ${name}`;
  // }
  getActionCaption({ actionCaption,translatedactionCaption, name,translation }) {
    if (actionCaption) return this.languageLocalize.transform(actionCaption,translatedactionCaption) +' '+ this.languageLocalize.transform(name,translation);
    else return this.translate.instant('choose-your')+` ${name}`;
  }
  checkNoPaymentOrdering() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowNoPaymentOrdering
    ) {
      this.allowNoPaymentOrdering =
        locationDefaults.locationData.orderSettings.allowNoPaymentOrdering;
    }
  }


    validateMaxItemsPerUser(selectedMenuItemId,selectedMenuCountToUpdate){
    const state = this.store.selectSnapshot((app) => app);
    const { menulist, cart,venuemanagement } = state;
    let guestCount = undefined;
      if(venuemanagement?.totalPersonPerTable?.count){
        guestCount = parseInt(venuemanagement.totalPersonPerTable['count']);
        }
        if(guestCount > 0){
          let selectedCategory;
          let cartItemPrice;

            if(cart.cart && cart.cart.itemPrice){
                  cartItemPrice = cart.cart.itemPrice;
                  let selectedItem = cart.cart.itemPrice.find(item=> item.itemId == selectedMenuItemId);
                  if(selectedItem){
                  let selectedCategoryId = selectedItem.menuSectionId;
                    selectedCategory = menulist.menuList.categories.find(cat =>cat._id == selectedCategoryId);
                  } else if(menulist.selectedCategory){
                    selectedCategory = menulist.selectedCategory;
                  }
            } else if(menulist.selectedCategory){
              selectedCategory = menulist.selectedCategory;
            }
          if(selectedCategory && selectedCategory.maxItemsPerOrder && selectedCategory.maxItemsPerOrder>0){
            if(cartItemPrice){
            let cartItemsFromCurrentCategory = cartItemPrice.filter(item=>item.menuSectionId == selectedCategory._id);
           // let selectedMenuDetails = cartItemPrice.find(item=>item.itemId == selectedMenuItemId);
            // let selectedSimilarMenuItemsList = cartItemPrice.filter(item=>item.itemId == selectedMenuItemId);
            // let updatedSimilarMenuItemCount = selectedSimilarMenuItemsList.reduce((total, eachItem) => {
            //                                       return total + eachItem.count;
            //                                   }, 0);
            // let selectedMenuDetailsCopy = {...selectedMenuDetails};
            // if(selectedMenuDetailsCopy)
            // selectedMenuDetailsCopy['count'] = updatedSimilarMenuItemCount;
            const totalItemCountPerCategory = cartItemsFromCurrentCategory.reduce((total, eachItem) => {
                return total + eachItem.count;
            }, 0);
            let totalItemsValidPerCategory = guestCount * selectedCategory.maxItemsPerOrder;

            if(this.isOrderItemCountExceeded(totalItemsValidPerCategory,totalItemCountPerCategory,selectedMenuCountToUpdate,cartItemPrice)){
              this.modal.showSmallModal(AlertPopupComponent,{data:{
                header:this.translate.instant('limit-reached'),
                content:this.translate.instant('limit-reached-description'),
                actionable:true,
                buttonText:this.translate.instant("continue")
              },
              whenClosed: (data) => {
               // if(data)this.countSubject.next(payload);
              },
            });
            //  if(selectedMenuDetails?.count) {
            //    this.value = selectedMenuDetails.count;
            //  } else {
            //    this.value = 0;
            //  }
              return false;
            } else {
              return true;
            }
            } else if(selectedMenuCountToUpdate > guestCount * selectedCategory.maxItemsPerOrder){
              this.modal.showSmallModal(AlertPopupComponent,{data:{
                header:this.translate.instant('limit-reached'),
                content:this.translate.instant('limit-reached-description'),
                actionable:true,
                buttonText:this.translate.instant("continue")
              },
              whenClosed: (data) => {
               // if(data)this.countSubject.next(payload);
              },
            });
              return false;
            }
            else {
              return true;
            }
          } else  {
            return true;
          }
          }  else if(guestCount == 0){
            this.sweetAlert.toast('error', 'You cant proceed without entering the number of guests.');
            return false;
        } else {
          return true;
        }
  }

  isOrderItemCountExceeded(totalItemsValidPerCategory,totalItemCountPerCategory,selectedMenuCountToUpdate,cartItemPrice?){
    let isExceeded;
    if(this.checkIfEdit){
      let isValid = this.validateEditModifierItem(totalItemsValidPerCategory,selectedMenuCountToUpdate,cartItemPrice,this.checkIfEdit);
      // if(((totalItemsValidPerCategory < selectedMenuCountToUpdate) && (!selectedMenuDetails || selectedMenuDetails.count < selectedMenuCountToUpdate)))
      // {
      //   isExceeded = true
      // } else isExceeded =  false;
      isExceeded = !isValid;
    } else if(
      !this.checkIfEdit
    ) {if(((totalItemsValidPerCategory < (totalItemCountPerCategory+selectedMenuCountToUpdate)))||(totalItemsValidPerCategory < selectedMenuCountToUpdate))
      {
        isExceeded = true
      } else isExceeded = false;
    }

    return isExceeded;
  }

  validateEditModifierItem(totalItemsValidPerCategory,selectedMenuCountToUpdate,cartItemPrice,selectedItemForEdit){
    let isValid = false;
    if(totalItemsValidPerCategory<selectedMenuCountToUpdate){
      isValid = false;
    } else {
      const selectedCategoryId = selectedItemForEdit.menuItem.menuSectionId['_id'];
      const filteredCartItemsInCurrentCategory = cartItemPrice.filter(cartItem=>cartItem.menuSectionId == selectedCategoryId);
      let selectedItemIndexInFilteredCart = filteredCartItemsInCurrentCategory.findIndex(cartItem=>cartItem.id == selectedItemForEdit['_id']);
      filteredCartItemsInCurrentCategory[selectedItemIndexInFilteredCart]['count'] = selectedMenuCountToUpdate;
      const totalItemCountInCategoryAfterUpdate = filteredCartItemsInCurrentCategory.reduce((total, eachItem) => {
        return total + eachItem.count;
      }, 0);
      if(totalItemCountInCategoryAfterUpdate > totalItemsValidPerCategory){
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  getStyle(){
    let ele = document.getElementsByClassName('modifier-banner');
    if(ele.length === 0){
      return{
        'background-color':'#f7f7f7'
      }
    }
  }
}
