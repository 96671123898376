import {
  Component,
  OnInit,
} from '@angular/core';
import { Store, Select } from '@ngxs/store';
import {
  BaseComponent,LocationState,VenueManagementState,environment,WhitelabelState
} from '@moduurnv2/libs-orderingapp/src';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { SetLocationAccess } from '@moduurnv2/libs-orderingapp/src/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'moduurnv2-wj-location-access',
  templateUrl: './wj-location-access.component.html',
  styleUrls: ['./wj-location-access.component.scss'],
})
export class WJLocationAccessComponent extends BaseComponent implements OnInit {
  numbers = new RegExp(/^[0-9]*$/);
  _modalClose: any = null;
  _data: any = null;
  organizationLogo: string;
  selectedLocationName:string ='';
  isLocationAccessLanding:boolean = true;
  imgBasePath: string = environment.image_basepath;
  location_access = "assets/images/WJ_Location_Access.png";

  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<any>;
  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
  selectedTimeDate = {
    date: null,
    time: null,
  };
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
  };
  themeOptionDescription: ThemeOptions = {
  };
  bannerImg:string;
  
  
  constructor(private store: Store, private modal: ModalService,public router: Router,private route :ActivatedRoute) {
    super();
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.organizationLogo =
      environment.imagebaseurl + whitelabel.restaurantLogo;
    this.bannerImg="assets/images/home-background-image.png";
  }
  ngOnInit() {
    let location = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if(!location){
      this.selectedLocation$.pipe(takeUntil(this.destroy$)).subscribe((location) => {
        if (location && location.name) {
          this.selectedLocationName = location.name
        }
      }); 
    } else if (location) {
      if (location.address) {
        this.selectedLocationName = location.name;
      }
    }
  }


  locationAccessDenied() {
    if(this.isLocationAccessLanding)
    this.isLocationAccessLanding =false;
    else
    this.isLocationAccessLanding =true;
  }
  back() {
    this.isLocationAccessLanding =true;
  }
  async continue() {
    const { queryParams } = this.route.snapshot;
    let tableNumber;
    if (queryParams && queryParams.tableNumber)
      tableNumber = queryParams.tableNumber;

    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    //let isLocPermissionGranted= await this.checkLocationPermission();
    const isAccessed = this.store.selectSnapshot(
      VenueManagementState.getLocationAccessEnabled
    );
    if(isAccessed){
      this.router.navigate([`locations/${selectedLocation._id}/home`],{ queryParams: { 'tableNumber':tableNumber }})
    } else {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            this.store.dispatch(new SetLocationAccess(true));
            this.router.navigate([`locations/${selectedLocation._id}/home`],{ queryParams: { 'tableNumber':tableNumber }})
          }
        },
        (error) => {
          this.store.dispatch(new SetLocationAccess(false));
          this.isLocationAccessLanding =false;
        }
        )
      }
    }
   // this.store.dispatch(new SetLocationAccess(locationAccess));
   // this.modal.closeAllModals();
    //this._modalClose(true);
  }

  checkLocationPermission(){
    return new Promise<boolean>(async (resolve, reject) => {
     let PermissionStatus = await navigator.permissions.query({name: 'geolocation'});
          if (PermissionStatus['state'] == 'granted') {
            resolve(true)
          } else {
            resolve(false)
          }
        })
  }

  checkFormat(event) {
    if (!this.numbers.test(event.target.value)) {
      return (event.target.value = '');
    }
  }
  set data(data: any) {
    this._data = data;
  }
}
